import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Table } from '../../../components/feesBenefitsTable/feesBenefitsTable'
import { StyledTableTitle } from '../../../components/feesBenefitsTable/styledFeesBenefitsTable'
import { StyledTableHeading } from '../../../fragments/ratesTable'
import { html } from '../../../utils/html'
import { nonNull } from '../../../utils/nonNull'
import { rem } from '../../../utils/rem'
import { ProductDetailsBenefitsTableFragment } from './__generated__/productDetailsBenefitsTable'

export type ProductBenefitsTableProps  = {
    data: ProductDetailsBenefitsTableFragment[]
}

const StyledBenefitsTableWrapper = styled(Grid)`
  padding-top: ${props => rem(50, props.theme)};
  padding-bottom: ${props => rem(50, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${props => rem(80, props.theme)};
    padding-bottom: ${props => rem(80, props.theme)};
  }
`

{/** @ts-ignore */}

export const ProductBenefitsTable: React.FC<PropsWithChildren<ProductBenefitsTableProps>> = ({ data }) => {
  if (!data || data[0]?.__typename !== 'productBenefitsTables_default_Entry') {
    return null
  }

  return (
    !data ? null :
      nonNull(data).map((table, index) => {
        { if (table && table.__typename === 'productBenefitsTables_default_Entry') {
          return (
            <StyledBenefitsTableWrapper container justifyContent = {'center'} key ={index}>
              <Grid item xs={12} sm={10} md ={8}>
                <Grid item xs={12}>
                  <StyledTableHeading>{table.tableTitle ?? 'KEY BENEFITS'}</StyledTableHeading>
                </Grid>
                <Table>
                  { table.productBenefitsTable?.[0] && table.productBenefitsTable?.[0]?.__typename === 'productBenefitsTable_BlockType' && (
                    table.productBenefitsTable?.[0]?.tableHeading && 
                                <Table title>
                                  <Grid item>
                                    <StyledTableTitle>{table.productBenefitsTable?.[0]?.tableHeading}</StyledTableTitle>
                                  </Grid>
                                </Table>
                  )}
                  { table.productBenefitsTable?.[0] && table.productBenefitsTable?.[0]?.__typename === 'productBenefitsTable_BlockType' && (
                    table.productBenefitsTable?.[0]?.benefitsTableContent && (
                      nonNull(table.productBenefitsTable?.[0]?.benefitsTableContent).map((content, index) => {
                        if ( content.__typename === 'benefitsTableContent_subTitleBenefits_BlockType') {
                          return (
                            <Table row key={index}>
                              <Table subTitle>
                                {content.leftSubtitleBenefits}
                              </Table>
                            </Table>
                          )
                        } else if ( content.__typename === 'benefitsTableContent_contentBenefits_BlockType') {
                          return(
                            <>
                              { content.leftColumnContentBenefits && 
                              <Table row key ={index}>
                                <Table contentLeft>
                                  <Table text>{html(content.leftColumnContentBenefits)}</Table>
                                </Table>
                                <Table contentRight/>
                              </Table>}
                            </>
                          )
                        }
                      })
                    )
                  )

                  }
                </Table>
              </Grid>
            </StyledBenefitsTableWrapper>
          )
        }

        null
        }
      })
  )  
}

export const PRODUCT_DETAILS_BENEFITS_TABLE =gql`
    fragment ProductDetailsBenefitsTableFragment on productBenefitsTables_default_Entry {
        tableTitle
        productBenefitsTable {
        ... on productBenefitsTable_BlockType {
            tableHeading
            benefitsTableContent {
            ... on benefitsTableContent_subTitleBenefits_BlockType {
                leftSubtitleBenefits
            }
            ... on benefitsTableContent_contentBenefits_BlockType {
                leftColumnContentBenefits
            }
            }
        }
        }
    }
`