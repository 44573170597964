import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { TabHeader, TabsPropsFixed } from '../../../components/tabs/tabHeader'
import { TabPanel } from '../../../components/tabs/tabPanel'
import { TabWrapper } from '../../../components/tabs/tabWrapper'
import { HeaderTabs } from '../../../components/tabs/tabs'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { ProductDetailsTabsFragment } from './__generated__/productDetailsTabsModule'


export type ProductDetailsTabsModuleProps = {
    data: ProductDetailsTabsFragment[]
}

export const ProductDetailsTabsModule: React.FC<PropsWithChildren<ProductDetailsTabsModuleProps>> = ({ data })=>{
  const numberofTabs = data.length
  const [selectedTab, setSelectedTab] = useState(0)
  const tabItems = data.slice(0,numberofTabs)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const onChange = useCallback(
    (_, newValue) => setSelectedTab(newValue),
    [setSelectedTab],
  ) as TabsPropsFixed['onChange']

  return (
    <TabWrapper>
      <TabHeader value={selectedTab} onChange={onChange} variant={'scrollable'}>
        { tabItems &&
          nonNull(tabItems).map((item, i) => {return item.__typename !== 'tabsModule_BlockType' ? null : (
            <HeaderTabs key={i} label={item.tabTitle}/>
          )
          })
        }
      </TabHeader>
      { tabItems &&
        nonNull(tabItems).map((item, i ) => {
          return item.__typename !== 'tabsModule_BlockType' ? null : (
            <TabPanel key={i} index={selectedTab} value={i}>
              <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={isDesktop ? 8 : 5}>
                {item.tabContent?.[1] ?
                  <>
                    <Grid item xs={12} md={6}>
                      {item.tabContent?.[0]?.columnContent && html(item.tabContent[0].columnContent)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {item.tabContent[1]?.columnContent && html(item.tabContent[1].columnContent)}
                    </Grid>
                  </>
                  :
                  <>
                    <Grid>
                      {item.tabContent?.[0]?.columnContent && html(item.tabContent[0].columnContent)}
                    </Grid>
                  </>
                }
              </Grid>
            </TabPanel>

          )
        })
      }

    </TabWrapper>
  )
}

export const PRODUCT_DETAILS_TABS_FRAGMENT = gql`
    fragment ProductDetailsTabsFragment on tabsModule_BlockType {
          tabTitle
          tabContent{
            ...on tabContent_column_BlockType{
              columnContent
            }
          }
        }

`
