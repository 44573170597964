import { gql } from '@apollo/client'
import { Grid } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import { Table } from '../../../components/feesBenefitsTable/feesBenefitsTable'
import {
  StyledDisclaimerBase,
  StyledDisclaimerBlock,
  StyledTableHeading,
  StyledTableWrapper,
} from '../../../fragments/ratesTable'
import { html } from '../../../utils/html'
import { nonNull } from '../../../utils/nonNull'
import { ProductDetailsFeesTableFragment } from './__generated__/productDetailsFeesTable'

export type ProductFeesTableProps = {
    data: ProductDetailsFeesTableFragment[]
}

{/** @ts-ignore */}

export const ProductFeesTables: React.FC<PropsWithChildren<ProductFeesTableProps>> =({ data }) => {
  if (!data || data[0]?.__typename !== 'productFeesTables_default_Entry') {
    return null
  }

  return (
    !data ? null :
      nonNull(data).map((table, index) => {
        { if (table && table.__typename === 'productFeesTables_default_Entry') {
          return (
            <StyledTableWrapper container justifyContent={'center'} key={index}>
              <Grid item xs={12}>
                <StyledTableHeading>{table.tableTitle}</StyledTableHeading>
              </Grid>
              <Grid item xs={12} sm={10} md ={8}>
                <Table>
                  <Table title>
                    <Table titleLeft>{'Fee'}</Table>
                    <Table titleRight>{'Amount'}</Table>
                  </Table>
                  { table.productFeesTable?.[0] && table.productFeesTable?.[0]?.__typename === 'productFeesTable_BlockType' && table.productFeesTable?.[0]?.feesTableContent && (
                    nonNull(table.productFeesTable?.[0]?.feesTableContent).map((content, index) => {
                      if (content.__typename === 'feesTableContent_subTitle_BlockType') {
                        return (
                          <Table row key={index}>
                            <Table subTitle>
                              {content.leftSubtitle}
                            </Table>
                          </Table>
                        )
                      } else if (content.__typename === 'feesTableContent_row_BlockType') {
                        return (
                          <Table row key={index}>
                            {content.leftColumnContent && 
                              <Table contentLeft>
                                <Table text>{html(content.leftColumnContent)}</Table>
                              </Table>
                            }
                            {content.rightColumnContent &&
                              <Table contentRight>
                                <Table text>{html(content.rightColumnContent)}</Table>
                              </Table>
                            }  
                          </Table>
                        )
                      }
                    })
                  )}
                </Table>
                {table.productFeesTable?.[0] && table.productFeesTable?.[0]?.__typename === 'productFeesTable_BlockType' &&
                  table.productFeesTable?.[0]?.dislaimer && (
                  nonNull(table.productFeesTable?.[0]?.dislaimer).map((item, index) => {
                    if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
                      return (
                        <StyledDisclaimerBlock>
                          <StyledDisclaimerBase key={index} textBlock>
                            {item.disclaimerText && html(item.disclaimerText)}
                          </StyledDisclaimerBase>
                        </StyledDisclaimerBlock>
                        
                      )
                    }
            
                    return null
                  })
                )}
              </Grid>
            </StyledTableWrapper>
          )
        }

        null
        }
      })
  )
}

export const PRODUCT_DETAILS_FEES_TABLE = gql`
    fragment ProductDetailsFeesTableFragment on productFeesTables_default_Entry {
        tableTitle
        productFeesTable {
        ... on productFeesTable_BlockType {
            feesTableContent {
            ... on feesTableContent_subTitle_BlockType {
                leftSubtitle
            }
            ... on feesTableContent_row_BlockType {
                leftColumnContent
                rightColumnContent
            }
            }
            dislaimer {
              ... on customDisclaimers_default_Entry {
                disclaimerText
              }
              ... on homeLoanDisclaimers_default_Entry {
                disclaimerText
              }
              ... on personalloandisclaimers_default_Entry {
                id
                disclaimerText
              }
            }
        }
        }
    }
`