import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { SecondaryPageBanner } from '../../../fragments/secondaryPageBanner'
import { GlobalContext } from '../../../globals/context'
import { isNotNull } from '../../../utils/nonNull'
import { ProductDetailsBannerFragment } from './__generated__/banner'

type BannerProps = {
  data: ProductDetailsBannerFragment[]
  breadcrumbs: React.ReactNode
}

export const ProductDetailsBanner: React.FC<PropsWithChildren<BannerProps>> = ({ data, breadcrumbs }) => {
  const { fallback } = useContext(GlobalContext)
  
  if (!data[0] || data[0].__typename !== 'secondaryPageBanner_BlockType' || fallback?.globalSet?.__typename !== 'siteSettings_GlobalSet') {
    return null
  }

  const { productDetailImageFallback } = fallback.globalSet

  const { secondaryBannerTitle, secondaryBannerSubtext, secondaryBannerPrimaryButton, secondaryBannerSecondaryButton, secondaryBannerImage } = data[0]

  const image = isNotNull(secondaryBannerImage) && secondaryBannerImage.length> 0 ? secondaryBannerImage: productDetailImageFallback as typeof secondaryBannerImage

  return (
    <SecondaryPageBanner
      after={'light'}
      title={secondaryBannerTitle}
      subTitle={secondaryBannerSubtext}
      primaryButton={secondaryBannerPrimaryButton}
      secondaryButton={secondaryBannerSecondaryButton}
      image={image}
      breadcrumbs={breadcrumbs}
    />
  )
}

export const PRODUCT_DETAILS_BANNER_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment ProductDetailsBannerFragment on secondaryPageBanner_BlockType {
    secondaryBannerImage {
      ...ImageFragment
    }
    secondaryBannerSubtext
    secondaryBannerTitle
    secondaryBannerPrimaryButton {
      url
      customText
      target
    }
    secondaryBannerSecondaryButton {
      url
      customText
      target
    }
  }
`
