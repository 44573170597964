import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { FeaturesList } from '../../../components/FeaturesList/FeaturesList'
import { irem, rem } from '../../../utils'
import { html } from '../../../utils/htmlParser'
import { ProductDetailsFeaturesListFragment } from './__generated__/productFeaturesList'


export type ProductDetailsFeatureProps = {
  data: ProductDetailsFeaturesListFragment
}

const StyledFeatureList = styled(Grid)`
  margin-top: ${props => rem(5, props.theme)};
  font-size: ${props => rem(15, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => rem(75, props.theme)};
    padding-bottom: ${props => rem(80, props.theme)};
    font-size: ${props => rem(17, props.theme)};
  }

  .MuiTypography-h3 {
    margin-bottom: ${irem(18)};
  }

  .MuiTypography-h4 {
    margin-top: 0;
    margin-bottom: ${irem(23)};
  }

  p + .MuiTypography-h4 {
    margin-top: ${irem(48)};
  }

  ul li {
    font-size: ${props => props.theme.typography.pxToRem(15)};

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
    }
  }

  p + ul {
    margin-top: -1em;
    font-size: ${props => props.theme.typography.pxToRem(15)};

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
    }
  }

  /* stylelint-disable */
  p,
  ul,
  h2,
  h3 {
    &:last-child {
      margin-bottom: 0;
    }
  }
  /* stylelint-enable */
`

export const ProductFeaturesList: React.FC<PropsWithChildren<ProductDetailsFeatureProps>> = ({ data }) => {
  if (!data[0] || data[0].__typename !== 'featuresListBlock_BlockType') {
    return null
  }

  const { leftColumnContent, rightColumnFeatureListItems } = data[0]

  return (
    <StyledFeatureList container spacing={5}>
      {
        leftColumnContent && 
          <Grid item md={6}> 
            {html(leftColumnContent)} 
          </Grid>
      }
      <Grid item md={6}>
        <FeaturesList>
          {rightColumnFeatureListItems && rightColumnFeatureListItems.map((section, index) => {
            return (
              <FeaturesList itemWrapper key={index}>
                <FeaturesList icon iconCode={section.icon} />
                <FeaturesList section>
                  <FeaturesList title>{section.itemTitle && section.itemTitle}</FeaturesList>
                  <FeaturesList description>{section.itemExcerpt && html(section.itemExcerpt)}</FeaturesList>
                </FeaturesList>
              </FeaturesList>
            )
          })}
        </FeaturesList>
      </Grid>
    </StyledFeatureList>
  )
}


export const FEATURES_LIST_FRAGMENT = gql`
  fragment ProductDetailsFeaturesListFragment on featuresListBlock_BlockType {
    leftColumnContent
    rightColumnFeatureListItems {
      ... on rightColumnFeatureListItems_featureListItem_BlockType {
        icon
        itemTitle
        itemExcerpt
      }
    }
  }
`
