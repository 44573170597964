import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Accordion } from '../../../components/accordion/accordion'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { rem } from '../../../utils/rem'
import { ProductDetailsCustomFaqFragment } from './__generated__/productDetailsCustomFaq'

export type ProductDetailsCustomFaqProps = {
  data: ProductDetailsCustomFaqFragment[]
};

export const StyledFaqHeader = styled(Grid)`
  margin: 0 0 ${props => rem(46, props.theme)} 0;

  h4 {
    text-transform: uppercase;
    font-size: ${props => rem(15, props.theme)};
    letter-spacing: ${props => rem(1.5, props.theme)};
    font-weight: 500;
    margin: 0;
    padding: 0 0 0 ${props => rem(20, props.theme)};

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${props => rem(17, props.theme)};
    }
  }

  p {
    margin: 0;
  }

  a {
    color: black;
  }

  ${props => props.theme.breakpoints.up('xs')} {
    flex-wrap: nowrap;

    .mobile {
      display: none;
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin: 0 0 ${props => rem(70, props.theme)} 0;
  }
`

const FAQContainer = styled(Grid)`
  > ul,
  > p {
    width: 100%;
    margin-bottom: ${props => rem(15, props.theme)};
  }

  > a {
    ${props => props.theme.breakpoints.up('md')} {
      height: ${props => rem(55, props.theme)};
    }
  }
`

export const ProductDetailsCustomFaq: React.FC<PropsWithChildren<ProductDetailsCustomFaqProps>> = ({
  data,
}) => {
  if (data?.[0]?.__typename !== 'faqs_default_Entry') {
    return null
  }

  return (
    <Grid container>
      <Accordion {...data}>
        {data && nonNull(data).map((item, key) => (item.__typename !== 'faqs_default_Entry' ? null :
          <Accordion panel key={key}>
            <Accordion title>{item.title}</Accordion>
            <Accordion content>
              <FAQContainer container>
                {item.faqText && html(item.faqText)}
              </FAQContainer>
            </Accordion>
          </Accordion>
        ))}
      </Accordion>
    </Grid>

  )
}

export const PRODUCT_DETAILS_CUSTOM_FAQ_FRAGMENT = gql`
  fragment ProductDetailsCustomFAQFragment on faqs_default_Entry {
    title
    faqText
  }
`
