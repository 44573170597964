import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { irem } from '../../utils'

export const StyledPercentage = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(24)};
  color: ${props => props.theme.palette.text.secondary};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledPA = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(12)};
  margin-top: ${props => props.theme.typography.pxToRem(-10)};
  color: ${props => props.theme.palette.text.primary};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    margin-top: ${props => props.theme.typography.pxToRem(-15)};
  }
`

export const StyledValue = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(42)};
  color: ${props => props.theme.palette.text.primary};
  font-weight: 300;
  position: relative;
  padding-right: ${props => props.theme.typography.pxToRem(30)};
  align-self: flex-start;

  & > span {
    position: absolute;
    top: 8px;
    right: 0;
    color: ${props => props.theme.palette.secondary.dark};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(61)};
  }
`

export const StyledDisclaimerLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main};
  text-decoration: underline;
  text-decoration-thickness: ${props => props.theme.typography.pxToRem(2)};
  text-underline-offset: ${props => props.theme.typography.pxToRem(3)};
  vertical-align: super;
  font-weight: 500;
  padding-left: ${props => props.theme.typography.pxToRem(3)};
  font-size: ${props => props.theme.typography.pxToRem(14)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }
`

export const ListingHeading = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(13)};
  color: ${props => props.theme.palette.text.primary};
  font-weight: ${props => props.theme.typography.fontWeightLight};
  align-self: flex-start;

  & > span {
    color: #C13501;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
  }
`

export const CurrencyValue = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(42)};
  font-weight: 300;
  position: relative;
  padding-left: ${props => props.theme.typography.pxToRem(10)};
  color: ${props => props.theme.palette.text.primary};
  align-self: flex-start;

  & > span {
    font-size: ${props => props.theme.typography.pxToRem(20)};
    position: absolute;
    top: 9px;
    left: 0;
    color: ${props => props.theme.palette.text.primary};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(61)};

    & > span {
      font-size: ${props => props.theme.typography.pxToRem(30)};
      left: ${props => props.theme.typography.pxToRem(-5)};
    }
  }
`

export const StyledTopContent = styled.div`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  text-align: left;
  padding-top: ${props => props.theme.typography.pxToRem(10)};
  align-self: flex-start;

  & > span {
    color: ${props => props.theme.palette.secondary.dark};
    font-weight: 500;
    padding-right: ${props => props.theme.typography.pxToRem(2)};
    display: inline-block;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    padding-top: ${props => props.theme.typography.pxToRem(20)};
    width: 100%;
  }

  & + & {
    padding-top: ${irem(5)};

    ${props => props.theme.breakpoints.up('sm')} {
      padding-top: ${irem(10)};
    }
  }

  & > a > sup {
    display: inline-block;
    text-decoration: none;
  }
`

export const StyledBottomContent = styled(StyledTopContent)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
`

export const StyledTopRow = styled(Grid)`
  width: 100%;
  padding-top: ${props => props.theme.typography.pxToRem(15)};
  padding-bottom: ${props => props.theme.typography.pxToRem(25)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(35)};
  }
`

export const StyledCenterRow = styled(Grid)`
  padding-left: ${props => props.theme.typography.pxToRem(15)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: 0;
  }
`

export const StyledCenterContent = styled(Grid)`
  padding-top: ${props => props.theme.typography.pxToRem(9.5)};
  padding-bottom: ${props => props.theme.typography.pxToRem(9.5)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 0;
  }
`


export const StyledBottomRow = styled(Grid)`
  width: 100%;
  padding-top: ${props => props.theme.typography.pxToRem(21)};

  ${props => props.theme.breakpoints.up('sm')} {
  padding-top: ${props => props.theme.typography.pxToRem(41)};
  }
`

export const StyledSubtitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  font-weight: 500;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
  }

  margin-bottom: 0;
  margin-top: ${props => props.theme.typography.pxToRem(5)};;
  word-break: break-word;
  text-align: left;

  & strong {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    font-weight: 500;

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(20)} !important;
    }
  }

  & a {
    text-decoration: none;
  }

  & a > sup {
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
    text-decoration: underline;
    text-decoration-thickness: ${props => props.theme.typography.pxToRem(2)};
    text-underline-offset: ${props => props.theme.typography.pxToRem(3)};
    vertical-align: super;
    font-weight: 500;
    padding-left: ${props => props.theme.typography.pxToRem(3)};
    font-size: ${props => props.theme.typography.pxToRem(14)};
  
    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(18)};
    }
  }
`

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.typography.pxToRem(1.6)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }

  margin-bottom: 0;
  margin-top: ${props => props.theme.typography.pxToRem(10)};;
  word-break: break-word;
  text-align: left;
`
