import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { Listing } from '../../../components/featureListing/featureListing'
import { StyledDisclaimerLink, StyledSubtitle, StyledTitle } from '../../../components/featureListing/styledFeatureListing'
import { Link } from '../../../components/link/link'
import { DisclaimerContext } from '../../../context/disclaimerContext'
import { DisclaimersFragment } from '../../../fragments/__generated__/ratesTable'
import { html } from '../../../utils/html'
import { isNotNull, nonNull } from '../../../utils/nonNull'
import { rem } from '../../../utils/rem'
import { DynamicFeatureListingFragment, FeatureListingFragment } from './__generated__/productFeatureListing'

export type ProductDetailsFeaturesListProps = {
  staticData: FeatureListingFragment[]
  dynamicData: DynamicFeatureListingFragment | { title: string } []
  
}

const StyledFeaturesListing = styled(Grid)`
  padding: ${props => rem(25, props.theme)} 0 0 0;

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => rem(50, props.theme)} 0 0 0;
  }
`

export const ProductFeatureListing: React.FC<PropsWithChildren<ProductDetailsFeaturesListProps>> = ({ staticData, dynamicData }) => {
  const { pageId } = useContext(DisclaimerContext)

  const hasDynamicData = dynamicData?.[0] && dynamicData?.[0]?.__typename === 'dynamicFeaturesListing_BlockType' && dynamicData?.[0]?.productRates?.[0]?.title
  const hasStaticData = staticData?.[0] && staticData?.[0]?.__typename === 'featuresListing_featuresListItemBlock_BlockType'


  if (hasDynamicData || hasStaticData) {
    let heading, disclaimer, listItem: FeatureListingFragment['listItem'], productDetails, dynamicProductRates

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (hasDynamicData) ({ heading, productRates: dynamicProductRates } = dynamicData[0]!)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (hasStaticData) ({ listItem, disclaimer, productDetails } = staticData[0]!)

    const validProductRates = dynamicProductRates && ['rates_default_Entry', 'productRatesCustom_default_Entry'].includes(dynamicProductRates?.[0]?.__typename)

    const rateTitle = dynamicData?.[0]?.productRates?.[0]?.title
    const purpose = dynamicData?.[0]?.productRates?.[0]?.ratePurpose
    const paymentType = dynamicData?.[0]?.productRates?.[0]?.rateRepaymentType
    const rateLvrMaximum = dynamicData?.[0]?.productRates?.[0]?.rateLvrMaximum

    return (
      <StyledFeaturesListing>
        <Listing>
          {(heading || rateTitle || productDetails) &&
            <Listing topRow>
              <StyledTitle variant="h5">{heading}</StyledTitle>
              {rateTitle ? (
                <StyledSubtitle variant="h3">
                  {rateTitle}

                  {paymentType && paymentType in REPAYMENT_TYPES && ` - ${REPAYMENT_TYPES[paymentType]}`}
                  {purpose && purpose in PURPOSE_TYPES && ` - ${PURPOSE_TYPES[purpose]}`}
                  {rateLvrMaximum && <span> &le;{rateLvrMaximum}% LVR </span>}
                  {dynamicProductRates?.[0] &&
                      <Link aria-label={'disclaimer block'} href={`#disclaimer_block_${pageId}`} passHref>
                          <StyledDisclaimerLink>
                              <DisclaimerIcons
                                  disclaimers={[...(dynamicProductRates?.[0]?.homeLoanDisclaimers ?? []), ...(dynamicProductRates?.[0]?.personalLoanDisclaimers ?? [])]}/>
                          </StyledDisclaimerLink>
                      </Link>}
                </StyledSubtitle>
              ) : (
                <>
                {productDetails && (
                    <StyledSubtitle variant="h3">
                      {html(productDetails)}
                    </StyledSubtitle>
                  )}
                </>)}
            </Listing>
          }
          {/* @ts-ignore */}
          {(validProductRates || listItem) &&
            <Listing centerRow>
              {(validProductRates && dynamicProductRates?.[0]?.productPrimaryRate) &&
                  <Listing
                    centerContent
                    value={dynamicProductRates?.[0].productPrimaryRate}
                    percentage
                    asterisk={false}
                  >
                    {dynamicProductRates?.[0].productPrimaryRateType}
                  </Listing>}
              {(validProductRates && dynamicProductRates?.[0]?.productComparisonRate) &&
                <Listing
                  centerContent
                  value={dynamicProductRates?.[0].productComparisonRate}
                  percentage
                  asterisk={false}
                >
                  {dynamicProductRates?.[0].productComparisonRateTitle}
                </Listing>}
              {/* @ts-ignore */} 
              {hasStaticData && listItem && listItem.map((item, index) => (!item?.itemTitle || item?.__typename !== 'listItem_BlockType' ? null :
                (
                  !dynamicProductRates ||
                  (dynamicProductRates && dynamicProductRates.length === 0) ||
                  (dynamicProductRates && dynamicProductRates?.length > 0 && index < 2)
                ) &&
                <Listing centerContent key={index} value={item.itemValue as string} asterisk={item.asterisk === true ? true : false}
                  {...item?.symbol ? { percentage: true } : {}}>{item.itemTitle}
                </Listing>
              ))}
            </Listing>
          }
          {(disclaimer?.[0]?.disclaimerContent !== null && (disclaimer && disclaimer?.[0]?.__typename === 'disclaimer_BlockType')) ?
            <Listing bottomRow>
              {disclaimer && disclaimer?.[0]?.__typename === 'disclaimer_BlockType' && (
                <Listing bottomContent asterisk={disclaimer?.[0]?.disclaimerAsterisk === true ? true : false}>
                  {disclaimer?.[0]?.disclaimerContent && html(disclaimer?.[0]?.disclaimerContent as string)}
                </Listing>
              )}
            </Listing>
            : null
          }

        </Listing>
      </StyledFeaturesListing>
    )
  }

  return null
}


export const FEATURE_LISTING_FRAGMENT = gql`
  fragment FeatureListingFragment on featuresListing_featuresListItemBlock_BlockType {
    listItem {
      ... on listItem_BlockType {
        itemTitle
        itemValue
        symbol
        asterisk
      }
    }
    productDetails
    disclaimer {
      ... on disclaimer_BlockType {
        disclaimerAsterisk
        disclaimerContent
      }
    }
  }
`

export const DYNAMIC_FEATURE_LISTING_FRAGMENT = gql`
  fragment DynamicFeatureListingFragment on dynamicFeaturesListing_BlockType {
    heading
    productRates {
      ... on rates_default_Entry {
        title
        productPrimaryRate
        productComparisonRate
        productPrimaryRateType
        productComparisonRateTitle
        rateRepaymentType
        ratePurpose
        rateLvrMinimum
        rateLvrMaximum
        homeLoanDisclaimers {
          ...DisclaimersFragment
        }
        personalLoanDisclaimers {
          ...DisclaimersFragment
        }
      }
      ... on productRatesCustom_default_Entry {
        title
        productPrimaryRate
        productComparisonRate
        productComparisonRateTitle
        productPrimaryRateType
        disclaimer_entry_field {
          ...DisclaimersFragment
        }
      }
    }
  }
`

export const DisclaimerIcons: React.VFC<{ disclaimers: Maybe<DisclaimersFragment>[] }> = ({ disclaimers }) => {
  const { iconMap } = useContext(DisclaimerContext)

  const icons = nonNull(disclaimers).map(d => {
    if ((d.__typename === 'homeLoanDisclaimers_default_Entry' || d.__typename === 'personalloandisclaimers_default_Entry') && d.disclaimerId && iconMap.has(d.disclaimerId)) {
      return (
        <span key={d.disclaimerId}>
          {iconMap.get(d.disclaimerId)}
        </span>
      )
    }

    return null
  }).filter(isNotNull)

  return (
    <>
      {icons}
    </>
  )
}


export const REPAYMENT_TYPES = {
  'IO': 'Interest Only',
  'P&I': 'Principal & Interest',
} as const

export const PURPOSE_TYPES = {
  'I': 'Investment',
  'OO': 'Owner Occupied',
} as const