import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { ContactCTA } from '../../../components/contactcta/contactcta'
import { isNotNull } from '../../../utils/nonNull'
import { ProductDetailsLinksCtaFragment } from './__generated__/productDetailsLinksCta'

export type ProductDetailsLinksCtaProps = {
    data: ProductDetailsLinksCtaFragment[]
}

export const ProductDetailsLinksCta: React.FC<PropsWithChildren<ProductDetailsLinksCtaProps>> = ({ data })=>{
  if (!data[0] || data?.[0]?.__typename !== 'linksCta_BlockType') {
    return null
  }

  const { 
    linksCtaTitle, 
    linksCtaSubtitle, 
    linksCTALink1, 
    linksCta1Subtext, 
    linksCTALink2, 
    linksCta2Subtext, 
    linksCTALink3, 
    linksCta3Subtext, 
    linksCTALink4,          
    linksCta4Icon,
    linksCta3Icon,
    linksCta2Icon,
    linksCta1Icon, 
    linksCta4Subtext } = data[0]

  return (
    <ContactCTA>
      <ContactCTA headingWrapper>
        {linksCtaTitle && <ContactCTA title>{linksCtaTitle}</ContactCTA>}
        {linksCtaSubtitle && <ContactCTA subtitle>{linksCtaSubtitle}</ContactCTA>}
      </ContactCTA>
      <ContactCTA contentWrapper>
        { linksCTALink1 && linksCTALink1.__typename === 'linkField_Link' && isNotNull(linksCTALink1.url) && isNotNull(linksCTALink1.customText) && (
          <ContactCTA section type="wrapper" href={linksCTALink1.url || '#'} target={ linksCTALink1.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(linksCta1Icon) ? <ContactCTA icon iconCode={linksCta1Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{linksCTALink1.customText}</ContactCTA>
              {linksCta1Subtext && <ContactCTA subtitle>{linksCta1Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        { linksCTALink2 && linksCTALink2.__typename === 'linkField_Link' && isNotNull(linksCTALink2.url) && isNotNull(linksCTALink2.customText) && (
          <ContactCTA section type="wrapper" href={linksCTALink2.url || '#'} target={ linksCTALink2.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(linksCta2Icon) ? <ContactCTA icon iconCode={linksCta2Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{linksCTALink2.customText}</ContactCTA>
              {linksCta1Subtext && <ContactCTA subtitle>{linksCta2Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        { linksCTALink3 && linksCTALink3.__typename === 'linkField_Link' && isNotNull(linksCTALink3.url) && isNotNull(linksCTALink3.customText) && (
          <ContactCTA section type="wrapper" href={linksCTALink3.url || '#'} target={ linksCTALink3.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(linksCta3Icon) ? <ContactCTA icon iconCode={linksCta3Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{linksCTALink3.customText}</ContactCTA>
              {linksCta1Subtext && <ContactCTA subtitle>{linksCta3Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        { linksCTALink4 && linksCTALink4.__typename === 'linkField_Link' && isNotNull(linksCTALink4.url) && isNotNull(linksCTALink4.customText) && (
          <ContactCTA section type="wrapper" href={linksCTALink4.url || '#'} target={ linksCTALink4.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(linksCta4Icon) ? <ContactCTA icon iconCode={linksCta4Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{linksCTALink4.customText}</ContactCTA>
              {linksCta1Subtext && <ContactCTA subtitle>{linksCta4Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
      </ContactCTA>
    </ContactCTA>

  )
}

export const PRODUCT_DETAILS_LINKS_CTA_FRAGMENT = gql`
  fragment ProductDetailsLinksCtaFragment on linksCta_BlockType {
    linksCtaTitle
    linksCtaSubtitle
    linksCta1Icon
    linksCTALink1 {
      url
      customText
      target
    }
    linksCta1Subtext
    linksCta2Icon
    linksCTALink2 {
      url
      customText
      target
    }
    linksCta2Subtext
    linksCta3Icon
    linksCTALink3 {
      url
      customText
      target
    }
    linksCta3Subtext
    linksCta4Icon
    linksCTALink4 {
      url
      customText
      target
    }
    linksCta4Subtext
  }
`
