import { gql } from '@apollo/client'
import { Container, Grid } from '@material-ui/core'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren, useMemo } from 'react'
import { Breadcrumb, Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs'
import { LazyComponent } from '../../components/lazyComponent/lazyComponent'
import {
  DisclaimerContext,
  DisclaimerIconMap,
  DisclaimerType,
} from '../../context/disclaimerContext'
import { RATES_TABLE_FRAGMENT } from '../../fragments/ratesTable'
import { nonNull } from '../../utils/nonNull'
import { iconObj } from '../common/disclaimerIcons'
import { PageQuery } from '../page/__generated__'
import { FLEXIBLE_CONTENT_FRAGMENT } from '../page/fragments/flexibleContent'
import {
  DYNAMIC_FEATURE_LISTING_FRAGMENT,
  FEATURES_LIST_FRAGMENT,
  FEATURE_LISTING_FRAGMENT,
  FULL_SCREEN_CTA_FRAGMENT,
  PRODUCT_DETAILS_APPLY_NOW_FRAGMENT,
  PRODUCT_DETAILS_AWARD_DISPLAY_FRAGMENT,
  PRODUCT_DETAILS_BANNER_FRAGMENT,
  PRODUCT_DETAILS_BENEFITS_TABLE,
  PRODUCT_DETAILS_CUSTOM_FAQ_FRAGMENT,
  PRODUCT_DETAILS_DISCLAIMER_FRAGMENT,
  PRODUCT_DETAILS_FEES_TABLE,
  PRODUCT_DETAILS_LINKS_CTA_FRAGMENT,
  PRODUCT_DETAILS_RELATED_ARTICLES_FRAGMENT,
  PRODUCT_DETAILS_TABS_FRAGMENT,
  ProductDetailsBanner,
  ProductFeatureListing,
  ProductFeaturesList,
} from './fragments'
import { ProductDetailsFeaturesListFragment } from './fragments/__generated__/productFeaturesList'
import { SlantedYellowBackground, StyledAltBgSection, StyledContainer } from './styledProductDetails'

export type ProductDetailsProps = PageQuery

const BelowTheFold = dynamic(() => import('./fragments/belowTheFold').then(mod => mod.BelowTheFold))

export const ProductDetails: React.FC<PropsWithChildren<ProductDetailsProps>> = (props) => {
  const { entry } = props

  if (!entry || entry.__typename !== 'products_pages_product_detail_Entry') {
    return null
  }

  const {
    id,
    secondaryPageBanner,
    dynamicFeaturesListing,
    featuresListing,
    featuresListBlock,
    productsRatePicker,
    parent,
    title,
    uri,
    fullScreenCta,
  } = entry

  const pageId = useMemo(() => {
    return id as string
  }, [id])

  const productDetailBreadcrumbs = useMemo(() => {
    return (
      <Breadcrumbs>
        <Breadcrumb href={'/'} home text={'Home'} />
        {parent?.parent && (
          <Breadcrumb href={`/${parent.parent.uri}`} text={parent.parent.title || ''} />
        )}
        {parent && (
          <Breadcrumb href={`/${parent.uri}`} text={parent.title || ''} />
        )}
        <Breadcrumb href={`/${uri}`} text={title || ''} />
      </Breadcrumbs>
    )
  }, [parent, title, uri])

  const disclaimerArray = useMemo(() => {
    const sortedDisclaimerArray:DisclaimerType[] = []

    const processRate = (rate, _) => {
      if (rate?.__typename === 'rates_default_Entry' || rate?.__typename === 'productRatesCustom_default_Entry') {
        if (rate.homeLoanDisclaimers && rate.homeLoanDisclaimers?.length > 0 && rate?.homeLoanDisclaimers?.[0]?.__typename === 'homeLoanDisclaimers_default_Entry') {
          nonNull(rate.homeLoanDisclaimers).map((item: DisclaimerType & { __typename: string }, _) => {
            if (item && item?.__typename === 'homeLoanDisclaimers_default_Entry') {
              if (sortedDisclaimerArray.some(sortedDisclaimerArray => sortedDisclaimerArray.disclaimerId === item.disclaimerId)) {
                return
              } else {
                sortedDisclaimerArray.push(item as DisclaimerType)
              }
            }

            return null
          })
        }
        else if (rate.personalLoanDisclaimers && rate.personalLoanDisclaimers?.length > 0 && rate?.personalLoanDisclaimers?.[0]?.__typename === 'personalloandisclaimers_default_Entry') {
          nonNull(rate.personalLoanDisclaimers).map((item: DisclaimerType & { __typename: string }, _) => {
            if (item && item?.__typename === 'personalloandisclaimers_default_Entry') {
              if (sortedDisclaimerArray.some(sortedDisclaimerArray => sortedDisclaimerArray.disclaimerId === item.disclaimerId)) {
                return
              } else {
                sortedDisclaimerArray.push(item as DisclaimerType)
              }
            }

            return null
          })
        }
        else if (rate.disclaimer_entry_field && rate.disclaimer_entry_field?.length > 0 && ['homeLoanDisclaimers_default_Entry', 'personalloandisclaimers_default_Entry'].includes(rate?.disclaimer_entry_field?.[0]?.__typename)) {
          nonNull(rate.disclaimer_entry_field).map((item: DisclaimerType & { __typename: string }, _) => {
            if (item && ['homeLoanDisclaimers_default_Entry', 'personalloandisclaimers_default_Entry'].includes(item?.__typename)) {
              if (sortedDisclaimerArray.some(sortedDisclaimerArray => sortedDisclaimerArray.disclaimerId === item.disclaimerId)) {
                return
              } else {
                sortedDisclaimerArray.push(item as DisclaimerType)
              }
            }

            return null
          })

          return null
        }

        return null
      }
    }

    productsRatePicker?.map((rates, _) => {
      rates?.__typename === 'rateTables_default_Entry' &&
      rates.rateTablePicker?.map(processRate)
    })

    dynamicFeaturesListing?.map((feature, _) => {
      feature?.__typename === 'dynamicFeaturesListing_BlockType' &&
      feature?.productRates?.map(processRate)
    })

    return sortedDisclaimerArray
  }, [productsRatePicker, dynamicFeaturesListing])

  const iconMap: DisclaimerIconMap = useMemo(() => {
    const iconMap = new Map()

    for (const [i, disclaimer] of disclaimerArray.entries()) {
      iconMap.set(disclaimer.disclaimerId, <>{iconObj[i] ? iconObj[i] : `~${i}`}</>)
    }

    return iconMap
  }, [disclaimerArray])

  return (
    <DisclaimerContext.Provider value={{ disclaimerArray, iconMap, pageId }}>
      {secondaryPageBanner &&
        <ProductDetailsBanner breadcrumbs={productDetailBreadcrumbs} data={nonNull(secondaryPageBanner)} />
      }
      <StyledAltBgSection $slanted={!fullScreenCta?.[0]}>
        {(dynamicFeaturesListing?.[0]?.productRates && dynamicFeaturesListing?.[0]?.productRates.length > 0) &&
          <SlantedYellowBackground>
            <Container maxWidth={'lg'}>
              <StyledContainer direction={'column'} alignItems={'center'} container>
                <Grid xs={12} item component={'section'}>
                  <ProductFeatureListing
                  //@ts-ignore
                    dynamicData={dynamicFeaturesListing ? nonNull(dynamicFeaturesListing) : []}
                    staticData={featuresListing ? nonNull(featuresListing) : []}
                  />
                </Grid>
              </StyledContainer>
            </Container>
          </SlantedYellowBackground>
        }
        {featuresListBlock &&
          <Container maxWidth={'lg'}>
            <StyledContainer direction={'column'} alignItems={'center'} container>
              <Grid item xs={12} component={'section'}>
                <ProductFeaturesList
                  //@ts-ignore
                  data={nonNull(featuresListBlock) as ProductDetailsFeaturesListFragment}
                />
              </Grid>
            </StyledContainer>
          </Container>
        }
      </StyledAltBgSection>
      <LazyComponent>
        <BelowTheFold { ...props } />
      </LazyComponent>
    </DisclaimerContext.Provider>
  )
}

export const PRODUCT_DETAILS_QUERY = gql`
  ${FLEXIBLE_CONTENT_FRAGMENT}
  ${PRODUCT_DETAILS_BANNER_FRAGMENT}
  ${DYNAMIC_FEATURE_LISTING_FRAGMENT}
  ${FEATURE_LISTING_FRAGMENT}
  ${FEATURES_LIST_FRAGMENT}
  ${FULL_SCREEN_CTA_FRAGMENT}
  ${RATES_TABLE_FRAGMENT}
  ${PRODUCT_DETAILS_LINKS_CTA_FRAGMENT}
  ${PRODUCT_DETAILS_APPLY_NOW_FRAGMENT}
  ${PRODUCT_DETAILS_TABS_FRAGMENT}
  ${PRODUCT_DETAILS_RELATED_ARTICLES_FRAGMENT}
  ${PRODUCT_DETAILS_DISCLAIMER_FRAGMENT}
  ${PRODUCT_DETAILS_CUSTOM_FAQ_FRAGMENT}
  ${PRODUCT_DETAILS_AWARD_DISPLAY_FRAGMENT}
  ${PRODUCT_DETAILS_FEES_TABLE}
  ${PRODUCT_DETAILS_BENEFITS_TABLE}

  fragment ProductDetailsFragment on products_pages_product_detail_Entry {
    id
    title
    uri
    parent {
      uid
      uri
      title

      parent {
        uid
        uri
        title
      }
    }
    secondaryPageBanner {
      ...ProductDetailsBannerFragment
    }
    fullScreenCta {
      ...ProducDetailsFullScreenCtaFragment
    }
    productsRatePicker {
      ...RatesTableFragment
    }
    productFeesPicker {
      ...ProductDetailsFeesTableFragment
    }
    productBenefitPicker {
      ...ProductDetailsBenefitsTableFragment
    }
    linksCta {
      ...ProductDetailsLinksCtaFragment
    }
    applyNowCtaPicker {
      ... on applyNowCtaProducts_default_Entry {
        applyNowModule {
          ...ProductDetailsApplyNowFragment
        }
      }
    }
    tabsModule {
      ...ProductDetailsTabsFragment
    }
    productDetailRelatedProducts {
      ...ProductDetailsRelatedArticlesFragment
    }
    relatesArticlesHeading
    disclaimer_entry_field {
      ...ProductDetailsDisclaimerFragment
    }
    generalDisclaimers {
      ...ProductDetailsDisclaimerFragment
    }
    newsFaqs {
      ...ProductDetailsCustomFAQFragment
    }
    newsFaqsCategory {
      id
      title
    }
    awardDisplayRow {
      ...AwardsDisplayFragment
    }
    flexibleContent {
      ...FlexibleContentFragment
    }
    dynamicFeaturesListing {
      ...DynamicFeatureListingFragment
    }
    featuresListing {
      ...FeatureListingFragment
    }
    featuresListBlock {
      ...ProductDetailsFeaturesListFragment
    }
  }
`
