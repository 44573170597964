import { Grid, Typography } from '@material-ui/core'
import React, { ComponentProps, PropsWithChildren } from 'react'
import {
  CurrencyValue,
  ListingHeading,
  StyledBottomContent,
  StyledBottomRow,
  StyledCenterContent,
  StyledCenterRow,
  StyledPA,
  StyledPercentage,
  StyledTopContent,
  StyledTopRow,
  StyledValue,
} from './styledFeatureListing'

export type ListingWrapperProps = ComponentProps<typeof Grid> & {
  //
}

export type ListingHeadingProps = ComponentProps<typeof Grid> & {
  //
}

export type ListingTopRowProps = ComponentProps<typeof Grid> & {
  //
}

export type ListingCenterRowProps = ComponentProps<typeof Grid> & {
  //
}

export type ListingBottomRowProps = ComponentProps<typeof Grid> & {
  //
}

export type ListingTopProps = ComponentProps<typeof Grid> & {
  value?: string
  percentage?: false | true
  asterisk?: false | true
}

export type ListingCenterProps = ComponentProps<typeof Grid> & {
  value?: string
  percentage?: false | true
  asterisk?: false | true
}

export type ListingBottomProps = ComponentProps<typeof StyledTopContent> & {
  asterisk?: false | true
}



export type ListingProps =
  | (
    {
      topRow?: never
      centerRow?: never
      bottomRow?: never
      topContent?: never
      centerContent?: never
      bottomContent?: never
    } & ListingWrapperProps
  )
  | (
    {
      topRow: true
      centerRow?: never
      bottomRow?: never
      topContent?: never
      centerContent?: never
      bottomContent?: never
    } & ListingTopRowProps
  )
  | (
    {
      topRow?: never
      centerRow?: true
      bottomRow?: never
      topContent?: never
      centerContent?: never
      bottomContent?: never
    } & ListingCenterRowProps
  )
  | (
    {
      topRow?: never
      centerRow?: never
      bottomRow?: true
      topContent?: never
      centerContent?: never
      bottomContent?: never
    } & ListingBottomRowProps
  )
  | (
    {
      topRow?: never
      centerRow?: never
      bottomRow?: never
      topContent?: true
      centerContent?: never
      bottomContent?: never
    } & ListingTopProps
  )
  | (
    {
      topRow?: never
      centerRow?: never
      bottomRow?: never
      topContent?: never
      centerContent?: true
      bottomContent?: never
    } & ListingCenterProps
  )
  | (
    {
      topRow?: never
      centerRow?: never
      bottomRow?: never
      topContent?: never
      centerContent?: never
      bottomContent?: true
    } & ListingBottomProps
  )

export const Listing: React.FC<PropsWithChildren<ListingProps>> = ({
  topRow,
  centerRow,
  bottomRow,
  topContent,
  centerContent,
  bottomContent,
  children,
  ...props
}) => {
  if (centerRow) {
    return (
      <StyledCenterRow
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={10}
      >
        {children}
      </StyledCenterRow>
    )
  }

  if (topRow) {
    return (
      <StyledTopRow
        item
        xs={10}
      >
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          {<Typography>{children}</Typography>}
        </Grid>
      </StyledTopRow>
    )
  }

  if (bottomRow) {
    return (
      <StyledBottomRow
        item
        xs={10}
      >
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Typography>{children}</Typography>
        </Grid>
      </StyledBottomRow>
    )
  }

  if (centerContent) {
    return (
      <CenterContent {...props}>{children}</CenterContent>
    )
  }

  if (topContent) {
    return (
      <TopContent {...props}>{children}</TopContent>
    )
  }

  if (bottomContent) {
    return (
      <BottomContent {...props}>{children}</BottomContent>
    )
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {children}
    </Grid>

  )
}

const CenterContent: React.FC<PropsWithChildren<ListingTopProps>> = ({ value, asterisk, percentage, children, ...props }) => {
  return (
    <StyledCenterContent
      item
      xs={6}
      sm={3}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <ListingHeading >{children} {asterisk && <span>*</span>}</ListingHeading>
        {percentage ? <Percentage>{value}</Percentage> : <CurrencyValue><span>$</span>{value}</CurrencyValue>}
      </Grid>
    </StyledCenterContent>
  )
}

const Percentage: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (

    <StyledValue>
      {children}
      <span>
        <StyledPercentage>%</StyledPercentage>
        <StyledPA>p.a.</StyledPA>
      </span>

    </StyledValue>
  )
}

const TopContent: React.FC<PropsWithChildren<ListingBottomProps>> = ({ asterisk, children }) => {
  return (
    <StyledTopContent>{asterisk && <span>*</span>}{children}</StyledTopContent>
  )
}

const BottomContent: React.FC<PropsWithChildren<ListingTopProps>> = ({ asterisk, children }) => {
  return (
    <StyledBottomContent>{asterisk && <span>*</span>}{children}</StyledBottomContent>
  )
}
