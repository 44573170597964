import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { AccountsCard } from '../../../components/accountsCard/accountsCard'
import { irem } from '../../../utils'
import { html } from '../../../utils/htmlParser'
import { isNotNull, nonNull } from '../../../utils/nonNull'
import { ProductDetailsApplyNowFragment } from './__generated__/productDetailsApplyNow'

export const StyledLink = styled.a`
  color: ${props => props.theme.palette.text.primary};
  text-decoration: none;
  padding-left: ${props => props.theme.typography.pxToRem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: ${irem(20)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${irem(24)};
  }

  &:hover {
    &::after {
      color: ${props => props.theme.palette.secondary.main};
      transform: translateX(${props => props.theme.typography.pxToRem(4)});
    }
  }
`

export type ProductDetailsApplyNowProps = {
  data: ProductDetailsApplyNowFragment[]
}

export const ProductDetailsApplynow: React.FC<PropsWithChildren<ProductDetailsApplyNowProps>> = ({ data }) => {
  if (!data[0] || data[0].__typename !== 'applyNowModule_BlockType') {
    return null
  }

  const { applyNowBlock, applyNowMiddleColumn, applyNowRightColumn } = data[0]

  return (
    <Grid item xs={12}>
      <AccountsCard>
        {
          applyNowBlock && applyNowBlock?.[0]?.columnContent && applyNowBlock?.[0].__typename === 'applyNowBlock_leftColumn_BlockType' && (
            <AccountsCard title>
              {html(applyNowBlock?.[0].columnContent)}
            </AccountsCard>
          )
        }
        {
          applyNowMiddleColumn &&
        <AccountsCard section>
          {nonNull(applyNowMiddleColumn).map((contentData, index) => {
            if (contentData && contentData.__typename === 'applyNowMiddleColumn_middleColumn_BlockType') {
              if (contentData?.heading?.[0]?.headingLink?.url && contentData?.heading?.[0]?.heading && contentData?.heading?.[0]?.icon) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      <Link legacyBehavior={true} href={contentData?.heading?.[0]?.headingLink?.url as string} passHref>
                        <StyledLink
                          target = {contentData?.heading?.[0]?.headingLink?.target === '_blank' ? '_blank' : '_self'}
                        > 
                          {contentData?.heading?.[0]?.heading} 
                        </StyledLink>
                      </Link>
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowMiddleColumn_middleColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if (contentData?.heading?.[0]?.icon && contentData?.heading?.[0]?.heading) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      {contentData?.heading?.[0]?.heading} 
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowMiddleColumn_middleColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if ( isNotNull(contentData?.columnContent) ) {
                return (
                  <AccountsCard subsection key ={index}>
                    <div>
                      {html(contentData?.columnContent)}
                    </div>
                  </AccountsCard>
                )
              }
            } 
            
            return null
          })}
        </AccountsCard>
        }
        {
          applyNowRightColumn &&
        <AccountsCard section>
          {nonNull(applyNowRightColumn).map((contentData, index) => {
            if (contentData && contentData.__typename === 'applyNowRightColumn_rightColumn_BlockType') {
              if (contentData?.heading?.[0]?.headingLink?.url && contentData?.heading?.[0]?.heading && contentData?.heading?.[0]?.icon) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      <Link legacyBehavior={true} href={contentData?.heading?.[0]?.headingLink?.url as string} passHref>
                        <StyledLink
                          target = {contentData?.heading?.[0]?.headingLink?.target === '_blank' ? '_blank' : '_self'}
                        > 
                          {contentData?.heading?.[0]?.heading} 
                        </StyledLink>
                      </Link>
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowRightColumn_rightColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if (contentData?.heading?.[0]?.icon && contentData?.heading?.[0]?.heading) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      {contentData?.heading?.[0]?.heading} 
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowRightColumn_rightColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if ( isNotNull(contentData?.columnContent) ) {
                return (
                  <AccountsCard subsection key ={index}>
                    <div>
                      {html(contentData?.columnContent)}
                    </div>
                  </AccountsCard>
                )
              }
            } 
            
            return null
          })}
        </AccountsCard>
        }
      </AccountsCard>
    </Grid>
  )
}

export const PRODUCT_DETAILS_APPLY_NOW_FRAGMENT = gql`
  fragment ProductDetailsApplyNowFragment on applyNowModule_BlockType {
    applyNowBlock {
      ... on applyNowBlock_leftColumn_BlockType {
        columnContent
      }
    }
    applyNowMiddleColumn {
      ... on applyNowMiddleColumn_middleColumn_BlockType {
        heading {
          ... on heading_BlockType {
            heading
            icon
            headingLink {
              url
              target
            }
          }
        }
        columnContent
      }
    }
    applyNowRightColumn {
      ... on applyNowRightColumn_rightColumn_BlockType {
        heading {
          ... on heading_BlockType {
            heading
            icon
            headingLink {
              url
              target
            }
          }
        }
        columnContent
      }
    }
  }
`