import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { CardDesignCarousel } from '../../../components/CardDesignCarousel/CardDesignCarousel'
import { CardDesignItem } from '../../../components/CardDesignCarousel/CardDesignItem'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { GlobalContext } from '../../../globals/context'
import { nonNull } from '../../../utils/nonNull'
import { rem } from '../../../utils/rem'
import { truncate } from '../../../utils/truncate'
import { StyledHeading } from '../../newsDetail/styledNewsDetail'
import { ProductDetailsRelatedArticlesFragment } from './__generated__/productDetailsRelatedArticles'

export type ProductDetailsRelatedArticlesProps = {
  title: string
  data: ProductDetailsRelatedArticlesFragment[]
}

const StyledRelatedArticleTitle = styled(Grid)`
  text-transform: uppercase;
  margin-bottom: ${props => rem(40, props.theme)};
`

export const ProductDetailsRelatedArticles: React.FC<PropsWithChildren<ProductDetailsRelatedArticlesProps>> = ({ data, title }) => {
  const { fallback } = useContext(GlobalContext)

  if (fallback?.globalSet?.__typename !== 'siteSettings_GlobalSet') {
    return null
  }

  const { flexibleContentImageFallback, newsFallbackImage, productDetailImageFallback, productListingImageFallback } = fallback.globalSet

  return (
    <>
      <StyledRelatedArticleTitle container justifyContent={'center'}>
        { title && (
          <StyledHeading variant={'h5'}>{title}</StyledHeading>
        )}
      </StyledRelatedArticleTitle>
      <CardDesignCarousel>
        {data && nonNull(data).map((item, index) => {
          let image
          let excerpt
          let title
          let uri

          switch (item.__typename) {
          case 'news_newsEntryType_Entry':
            image = item?.newsDetailBanner?.[0]?.bannerImage?.[0] || newsFallbackImage?.[0]
            title = item?.title
            excerpt = item?.newsArticleDescription ?? item?.newsDetailBanner?.[0]?.bannerSubText
            uri = item?.uri
            break
          case 'newsLandingPage_newsLandingPage_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            break

          case 'products_pages_product_detail_Entry':
            image = item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] || productDetailImageFallback?.[0]
            title = item?.title
            excerpt = item?.metaExcerpt
            uri = item?.uri
            break

          case 'products_pages_product_fcp_Entry':
            image = item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] || flexibleContentImageFallback?.[0]
            title = item?.title
            excerpt = item?.metaExcerpt
            uri = item?.uri
            break

          case 'products_pages_product_listing_Entry':
            image = item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0]?.url ? item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] : productListingImageFallback?.[0]
            title = item?.title
            excerpt = item?.metaExcerpt
            uri = item?.uri
            break

          case 'faqLandingPage_faqLandingPage_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            break

          case 'glossaryPage_glossaryPage_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            break

          case 'search_search_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            break
          }

          return (
            uri && <CardDesignItem key={index} href={uri ? `/${uri}` : '#'}>
              {image?.__typename === 'images_Asset' && (
                <CardDesignItem image src={image?.url} alt={image.alt} layout="fill" objectFit="cover" objectPosition="center" />
              )}
              <CardDesignItem title>{title ? truncate(title, 75) : 'Untitled'}</CardDesignItem>
              {excerpt && <CardDesignItem description>{truncate(excerpt, 200)}</CardDesignItem>}
            </CardDesignItem>
          )
        })}
      </CardDesignCarousel>
    </>
  )
}


export const PRODUCT_DETAILS_RELATED_ARTICLES_FRAGMENT = gql `
  ${IMAGE_FRAGMENT}

  fragment ProductDetailsRelatedArticlesFragment on EntryInterface {
    ... on glossaryPage_glossaryPage_Entry {
      id
      uri
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on products_pages_product_listing_Entry {
      id
      uri
      title
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ... on products_pages_product_detail_Entry {
      id
      uri
      title
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ... on products_pages_product_fcp_Entry {
      id
      uri
      title
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ...on search_search_Entry {
      id
      uri
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on faqLandingPage_faqLandingPage_Entry {
      id
      uri
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on newsLandingPage_newsLandingPage_Entry {
      id
      uri
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on news_newsEntryType_Entry {
      id
      uri
      title
      newsArticleDescription
      newsDetailBanner {
        ... on newsDetailBanner_BlockType {
          bannerSubText
          bannerImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`
