import { Container, Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { irem, rem } from '../../utils/rem'
import { productDetailHrStyles, StyledAwardDescription, StyledAwardImages } from './fragments'
import { FAQHeader } from '../newsDetail/styledNewsDetail'
import { StyledCarouselContainer } from '../../components/CardDesignCarousel/styledCardDesignCarousel'
import { StyledFlexibleContentWrapper } from '../../components/flexibleContent/flexibleContentWrapper'
import { StyledCTAWrapper } from '../../components/contactcta/styledcontractcta'

export const StyledContainer = styled(Grid)`
  > section {
    width: 100%;
  }
`

export const slantedStyles = css`
  margin-top: ${props => rem(40, props.theme)};
  padding-top: ${props => rem(100, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => rem(120, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${props => rem(150, props.theme)};
  }

  &::after {
    bottom: ${props => rem(20, props.theme)};
    clip-path: polygon(0 ${props => rem(30, props.theme)}, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(80, props.theme)}));

    ${props => props.theme.breakpoints.up('sm')} {
      clip-path: polygon(0 ${props => rem(80, props.theme)}, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(120, props.theme)}));
    }

    ${props => props.theme.breakpoints.up('md')} {
      top: ${props => rem(-50, props.theme)};
      clip-path: polygon(0 ${props => rem(150, props.theme)}, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(150, props.theme)}));
    }
  }
`

export const flatStyles = css`
  margin: 0;
  padding: 0;

`

export const StyledAltBgSection = styled.section<{ $slanted?: boolean }>`
  background: #f8f8f8;
  width: 100%;
  margin-bottom: ${props => rem(200, props.theme)};
  position: relative;

  ${props => props.$slanted && css`
    &:before {
      background: #f8f8f8;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(120, props.theme)}));
      padding-bottom: ${irem(170)};
      content: '';
      height: ${props => rem(93, props.theme)};
      position: absolute;
      left: 0;
      bottom: ${irem(-170)};
      width: 100%;

  
      ${props => props.theme.breakpoints.up('md')} {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(150, props.theme)}));
      }
    }
  `}
`

export const StyledAltWhiteBgSection = styled.section<{ $slanted?: boolean }>`
  position: relative;

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    position: absolute;
    z-index: -1;
  }

  ${props => props.$slanted ? slantedStyles : flatStyles}
`

export const SlantedYellowBackground = styled.div`
  background-color: #fff7e5;
  padding: 0 0 ${props => rem(56, props.theme)} 0;
  position: relative;

  ${props => props.theme.breakpoints.down('xs')} {
    padding: 0 0 ${props => rem(40, props.theme)} 0;
  }

  &:before {
    background-color: #fff7e5;
    clip-path: polygon(0 ${props => rem(93, props.theme)}, 100% 0, 100% 100%, 0 100%);
    content: '';
    height: ${props => rem(93, props.theme)};
    position: absolute;
    left: 0;
    top: ${props => rem(-93, props.theme)};
    width: 100%;

    ${props => props.theme.breakpoints.down('xs')} {
      top: ${props => rem(-35, props.theme)};
      clip-path: polygon(0 ${props => rem(35, props.theme)}, 100% 0, 100% 100%, 0 100%);
      z-index: -1;
    }
  }
`

export const SlantedBottomGreyBackground = styled.div<{ $last?: boolean }>`
  z-index: 1;
  position: relative;
  margin-top: ${props => rem(props.$last ? 30 : 0, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${props => rem(props.$last ? 10 : 155, props.theme)};
  }

  &::before {
    content: "";
    top: 0;
    right: 0;
    left: 0;
    background: #f2f2f2;
    position: absolute;
    z-index: -1;
    bottom: ${props => rem(props.$last ? 150 : -160, props.theme)};

    ${props => props.theme.breakpoints.up('md')} {
      bottom: ${props => rem(props.$last ? 50 : -195, props.theme)};
    }
  }

  > div.MuiContainer-maxWidthLg > ${StyledCTAWrapper} {
    margin-top: ${props => rem(104, props.theme)};

    ${props => props.theme.breakpoints.down('sm')} {
      margin-bottom: 0;
    }

    ${props => props.theme.breakpoints.up('md')} {
      margin-top: ${props => rem(160, props.theme)};
    }
  }

  ${StyledCarouselContainer} {
    padding-bottom: 0;
  }
`
export const SlantedTopGreyBackground = styled.div`
  position: relative;

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f2f2f2;
    opacity: 0.5;
    position: absolute;
    z-index: -1;
    clip-path: polygon(0 ${props => rem(150, props.theme)}, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(150, props.theme)}));
  }

  &::after {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f2f2f2;
    position: absolute;
    z-index: -1;
    clip-path: polygon(0 ${props => rem(150, props.theme)}, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(150, props.theme)}));
  }
`
export const StyledFullScreenCtaContainer = styled(Grid)`
  margin-top: ${props => rem(-200, props.theme)};
  margin-bottom: ${props => rem(60, props.theme)};
  position: relative;
  
  &::before {
    position: absolute;
    width: 100%;
    height: 50%;
    background: #f8f8f8;
    content: "";
    top: ${props => rem(-30, props.theme)};
    right: 0;
    z-index: -1;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(-175, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => rem(-175, props.theme)};
  }
`
export const StyledApplyNowContainer = styled(Grid)`
  padding: ${props => rem(30, props.theme)} 0 ${props => rem(100, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => rem(70, props.theme)} 0 ${props => rem(170, props.theme)};
  }
`

export const StyledProductDetailsTabsModule = styled(Grid)`
  width: 100%;
  padding: ${props => rem(90, props.theme)} 0 ${props => rem(70, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => rem(120, props.theme)} 0 ${props => rem(40, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => rem(20, props.theme)} 0 ${props => rem(30, props.theme)};
  }
`

export const StyledCustomFaq = styled(Grid)`
  margin: 0 0 ${props => rem(80, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin: 0 auto ${props => rem(80, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin: ${props => rem(30, props.theme)} auto ${props => rem(170, props.theme)};
  }

  ${FAQHeader} {
    margin-bottom: ${irem(30)};
  }
`

export const StyledRelatedArticles = styled(Grid)`
  padding: ${props => rem(155, props.theme)} 0 ${props => rem(104, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => rem(165, props.theme)} 0 ${props => rem(157, props.theme)};
  }
`

export const StyledDisclaimer = styled(Grid)`
  padding: ${props => rem(70, props.theme)} 0;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => rem(103, props.theme)} 0;
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${irem(150)};
  }
`

export const StyledCardCarouselContainer = styled(Container)`
  padding-top: 0;
  margin-bottom: ${props => props.theme.typography.pxToRem(-80)};
  padding-left: 0;
  padding-right: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: ${props => props.theme.typography.pxToRem(19)};
    padding-right: ${props => props.theme.typography.pxToRem(19)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(60)};
    margin-bottom: ${props => props.theme.typography.pxToRem(-120)};
  }

  .flickity-page-dots {
    position: relative;
    bottom: 0;
    margin-top: ${props => props.theme.typography.pxToRem(43)};
    margin-bottom: 0;

    ${props => props.theme.breakpoints.up('md')} {
      margin-top: ${props => props.theme.typography.pxToRem(71)};
    }
  }
`

export const ApplyNowWrapper = styled(Grid)`
  margin-top: ${props => props.theme.typography.pxToRem(30)};
  margin-bottom: ${props => props.theme.typography.pxToRem(103)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(80)};
    margin-bottom: ${props => props.theme.typography.pxToRem(174)};
  }
`
export const FlexibleComponentWrapper = styled.div<{ $hr?: boolean }>`
  margin-top: ${props => props.theme.typography.pxToRem(30)};
  margin-bottom: ${props => props.theme.typography.pxToRem(160)};

  & #flexibleSlantedLine {
    display: none;
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.$hr ? props.theme.typography.pxToRem(138) : '0'};
    margin-bottom: ${props => props.theme.typography.pxToRem(60)};

    && {
      padding-top: ${props => props.$hr ? irem(50) : '0'};
    }
  }

  ${props => props.$hr && productDetailHrStyles}
  ${StyledFlexibleContentWrapper} {
    margin-top: 0;

    ${props => props.theme.breakpoints.up('md')} {
      margin-top: ${props => props.$hr ? props.theme.typography.pxToRem(78) : '0'};
    }
  }
`

export const StyledProductDetailsWrapper = styled(Grid)`
  ${StyledAwardImages} {
    max-width: 100%;
  }

  ${StyledAwardDescription} {
    h3 {
      margin-bottom: ${props => props.theme.typography.pxToRem(10)};
    }
  }
`
