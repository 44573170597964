import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { Disclaimer as DisclaimerBase } from '../../../components/disclaimer/disclaimer'
import { DisclaimerContext } from '../../../context/disclaimerContext'
import { StyledDisclaimerBase, StyledDisclaimerSymbol } from '../../../fragments/ratesTable'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { ProductDetailsDisclaimerFragment } from './__generated__/productDetailsDisclaimer'

export const StyledDisclaimerWrapper = styled(DisclaimerBase)`
  padding: 0 ${props => props.theme.typography.pxToRem(10)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 0;
  }

  > div:first-child {
    padding-bottom: ${props => props.theme.typography.pxToRem(35)};
  }

  > div:nth-child(2) {
    padding-top: ${props => props.theme.typography.pxToRem(25)};
    padding-bottom: 0;
  }

  > div:nth-child(n+3) {
    padding-bottom: 0;
  }

  > div:last-child {
    padding-bottom: ${props => props.theme.typography.pxToRem(25)};
  }
`
export const StyledDisclaimerTitle= styled(DisclaimerBase)`
  & > h5 {
    margin-left: ${props => props.theme.typography.pxToRem(20)};
  }
`

export type ProductDetailsDisclaimerProps = {
  data: ProductDetailsDisclaimerFragment[]
  general?: ProductDetailsDisclaimerFragment[]
}

export const ProductDetailsDisclaimer: React.FC<PropsWithChildren<ProductDetailsDisclaimerProps>> = ({ data, general }) =>{
  const { disclaimerArray, iconMap, pageId } = useContext(DisclaimerContext)

  return (
    <>
      { ((disclaimerArray && disclaimerArray.length > 0) || (general && general.length > 0) || (data && data.length > 0)) &&
      <StyledDisclaimerWrapper>
        <StyledDisclaimerTitle id={`disclaimer_block_${pageId}`} title>{'INFORMATION YOU SHOULD KNOW'}</StyledDisclaimerTitle>
        { general && nonNull(general).map((item, index) => {
          if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
            return (
              <StyledDisclaimerBase key={index} textBlock>
                {item.disclaimerText && html(item.disclaimerText)}
              </StyledDisclaimerBase>
            )
          }

          return null
        })}
        {disclaimerArray && nonNull(disclaimerArray).map((d, index) => {
          return (
            <StyledDisclaimerBase key={index} textBlock>
              <StyledDisclaimerSymbol>
                {iconMap.get(d.disclaimerId)}
              </StyledDisclaimerSymbol>
              {d?.disclaimerText && html(d.disclaimerText)}
            </StyledDisclaimerBase>
          )
        })}
        { data && nonNull(data).map((item, index) => {
          if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
            return (
              <StyledDisclaimerBase key={index} textBlock>
                <StyledDisclaimerSymbol key={index}>
                  {index+1}
                </StyledDisclaimerSymbol>
                {item.disclaimerText && html(item.disclaimerText)}
              </StyledDisclaimerBase>
            )
          }

          return null
        })}
      </StyledDisclaimerWrapper>
      }
    </>
  )
}

export const PRODUCT_DETAILS_DISCLAIMER_FRAGMENT = gql`
  
  fragment ProductDetailsDisclaimerFragment on EntryInterface {
    ... on customDisclaimers_default_Entry {
      disclaimerText
    }
    ... on homeLoanDisclaimers_default_Entry {
      disclaimerText
    }
    ... on personalloandisclaimers_default_Entry {
      disclaimerText
    }
  }
`
