import { Container, Grid } from '@material-ui/core'
import React, { PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'
import { RatesTable } from '../../../fragments/ratesTable'
import { isNotNull, nonNull } from '../../../utils/nonNull'
import { rem } from '../../../utils/rem'
import { FAQHeader, StyledAnchor, StyledHeading } from '../../newsDetail/styledNewsDetail'
import { FlexibleContent } from '../../page/fragments/flexibleContent'
import { ProductDetailsProps } from '../index'
import {
  ApplyNowWrapper,
  FlexibleComponentWrapper,
  SlantedBottomGreyBackground,
  StyledAltWhiteBgSection,
  StyledCardCarouselContainer,
  StyledContainer,
  StyledCustomFaq,
  StyledDisclaimer,
  StyledFullScreenCtaContainer,
  StyledProductDetailsTabsModule,
  StyledProductDetailsWrapper,
} from '../styledProductDetails'
import { ProductDetailsApplyNowFragment } from './__generated__/productDetailsApplyNow'
import { ProductDetailsBenefitsTableFragment } from './__generated__/productDetailsBenefitsTable'
import { ProductDetailsCustomFaqFragment } from './__generated__/productDetailsCustomFaq'
import { ProductDetailsDisclaimerFragment } from './__generated__/productDetailsDisclaimer'
import { ProductDetailsFeesTableFragment } from './__generated__/productDetailsFeesTable'
import { ProductDetailsRelatedArticlesFragment } from './__generated__/productDetailsRelatedArticles'
import { FullScreenCta } from './fullScreenCta'
import { ProductDetailsApplynow } from './productDetailsApplyNow'
import { ProductDetailsAwardDisplay } from './productDetailsAwardDisplay'
import { ProductBenefitsTable } from './productDetailsBenefitsTable'
import { ProductDetailsCustomFaq } from './productDetailsCustomFaq'
import { ProductDetailsDisclaimer } from './productDetailsDisclaimer'
import { ProductFeesTables } from './productDetailsFeesTable'
import { ProductDetailsLinksCta } from './productDetailsLinksCta'
import { ProductDetailsRelatedArticles } from './productDetailsRelatedArticles'
import { ProductDetailsTabsModule } from './productDetailsTabsModule'

const StyledTableContainer = styled(Container).attrs({
  maxWidth: 'lg',
})`
  padding: 0 ${props => props.theme.spacing(1)};
`

const FAQsContainer = styled(Container)`
  padding-top: ${props => rem(250, props.theme)};
  margin-top: ${props => rem(-250, props.theme)};
`


export const BelowTheFold: React.FC<PropsWithChildren<ProductDetailsProps>> = ({ entry, linksCtaFallback }) => {
  if (!entry || entry.__typename !== 'products_pages_product_detail_Entry') {
    return null
  }

  const {
    fullScreenCta,
    productsRatePicker,
    productFeesPicker,
    productBenefitPicker,
    applyNowCtaPicker,
    tabsModule,
    linksCta,
    disclaimer_entry_field,
    productDetailRelatedProducts,
    awardDisplayRow,
    flexibleContent,
    newsFaqs,
    newsFaqsCategory,
    generalDisclaimers,
    relatesArticlesHeading,
  } = entry

  const getFallbackCta = () => {
    if (linksCta && linksCta?.[0]?.__typename === 'linksCta_BlockType') {
      return linksCta
    } else if (linksCtaFallback && linksCtaFallback.__typename === 'ctasProductPages_GlobalSet') {
      const { globalLinksCta } = linksCtaFallback

      return globalLinksCta
    }
  }

  const relatedCarouselTitle = useMemo(() => {
    let carouselHeading

    switch (relatesArticlesHeading) {
    case 'guidesAndNews':
      carouselHeading = 'GUIDES AND NEWS'
      break
    case 'relatedProducts':
      carouselHeading = 'RELATED PRODUCTS'
      break
    case 'relatedContent':
    default:
      carouselHeading = 'RELATED CONTENT'
      break
    }

    return carouselHeading
  }, [relatesArticlesHeading])


  return (
    <>
      {fullScreenCta?.[0] &&
      <StyledFullScreenCtaContainer>
        <FullScreenCta data={nonNull(fullScreenCta)}/>
      </StyledFullScreenCtaContainer>
      }
      <StyledAltWhiteBgSection>
        <StyledTableContainer>
          {productsRatePicker &&
          <>
            <StyledContainer direction={'column'} alignItems={'center'}>
              <Grid item component={'section'}>
                {nonNull(productsRatePicker).map((table, i) => (
                  <RatesTable key={i} { ...table } />
                ))}
              </Grid>
            </StyledContainer>
          </>
          }
          {productFeesPicker &&
          <StyledContainer direction={'column'} alignItems={'center'} container>
            <Grid item component={'section'}>
              <ProductFeesTables data={nonNull(productFeesPicker) as ProductDetailsFeesTableFragment[]}/>
            </Grid>
          </StyledContainer>
          }
          {productBenefitPicker &&
          <StyledContainer direction={'column'} alignItems={'center'} container>
            <Grid item component={'section'}>
              <ProductBenefitsTable data={nonNull(productBenefitPicker) as ProductDetailsBenefitsTableFragment[]}/>
            </Grid>
          </StyledContainer>
          }
        </StyledTableContainer>
        {applyNowCtaPicker && applyNowCtaPicker?.[0]?.__typename === 'applyNowCtaProducts_default_Entry' &&
        <Container maxWidth={'lg'}>
          <StyledContainer direction={'column'} alignItems={'center'} container>
            <ApplyNowWrapper item>
              <ProductDetailsApplynow
                data={nonNull(applyNowCtaPicker?.[0]?.applyNowModule as ProductDetailsApplyNowFragment[])}/>
            </ApplyNowWrapper>
          </StyledContainer>
        </Container>
        }
        <FAQsContainer maxWidth={'lg'} id="faq">
          <StyledCustomFaq direction={'column'} alignItems={'center'} container>
            {newsFaqsCategory && newsFaqsCategory[0] &&
          <FAQHeader container sm={10} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
            <StyledHeading variant={'h5'}>FREQUENTLY ASKED QUESTIONS</StyledHeading>
            <StyledAnchor
              href={`/help-and-support/faqs?category=${newsFaqsCategory[0].id}`}>{isNotNull(newsFaqsCategory[0].title) ? `View all ${newsFaqsCategory[0].title} FAQs` : 'View all FAQs'}</StyledAnchor>
          </FAQHeader>
            }
            <Grid item sm={10}>
              {newsFaqs &&
            <ProductDetailsCustomFaq data={nonNull(newsFaqs) as ProductDetailsCustomFaqFragment[]}/>
              }
            </Grid>
          </StyledCustomFaq>
        </FAQsContainer>
      </StyledAltWhiteBgSection>
      <SlantedBottomGreyBackground>
        {tabsModule && tabsModule.length > 0 &&
        <StyledContainer direction={'column'} alignItems={'center'} container>
          <StyledProductDetailsTabsModule item>
            <ProductDetailsTabsModule data={nonNull(tabsModule)}/>
          </StyledProductDetailsTabsModule>
        </StyledContainer>
        }
        {awardDisplayRow &&
        <Container maxWidth={'lg'}>
          <StyledContainer direction={'column'} justifyContent={'center'} alignItems={'center'} container>
            {/** @ts-ignore */}
            <StyledProductDetailsWrapper item component={'section'}>
              <ProductDetailsAwardDisplay data={nonNull(awardDisplayRow)}
                hr={Boolean(tabsModule && tabsModule.length > 0)}/>
            </StyledProductDetailsWrapper>
          </StyledContainer>
        </Container>
        }
        {flexibleContent && flexibleContent.length > 0 &&
        <Container maxWidth={'lg'}>
          <FlexibleComponentWrapper
            $hr={Boolean(((awardDisplayRow && awardDisplayRow.length > 0) || (tabsModule && tabsModule.length > 0)) && flexibleContent.length > 0)}>
            {/** @ts-ignore */}
            <FlexibleContent content={nonNull(flexibleContent)}/>
          </FlexibleComponentWrapper>
        </Container>
        }
      </SlantedBottomGreyBackground>
      <SlantedBottomGreyBackground $last>
        {productDetailRelatedProducts &&
        <StyledCardCarouselContainer>
          <ProductDetailsRelatedArticles
            data={nonNull(productDetailRelatedProducts) as ProductDetailsRelatedArticlesFragment[]}
            title={relatedCarouselTitle}
          />
        </StyledCardCarouselContainer>
        }
        {linksCta &&
        <Container>
          {/** @ts-ignore */}
          <ProductDetailsLinksCta data={nonNull(getFallbackCta())}/>
        </Container>
        }
      </SlantedBottomGreyBackground>
      <StyledAltWhiteBgSection>
        <Container>
          <StyledDisclaimer container>
            <Grid item>
              {(disclaimer_entry_field || generalDisclaimers) &&
              <ProductDetailsDisclaimer data={nonNull(disclaimer_entry_field ?? []) as ProductDetailsDisclaimerFragment[]} general={nonNull(generalDisclaimers ?? []) as ProductDetailsDisclaimerFragment[]} />
              }
            </Grid>
          </StyledDisclaimer>
        </Container>
      </StyledAltWhiteBgSection>
    </>
  )
}