import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Image from 'next/legacy/image'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { irem, rem } from '../../../utils'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { AwardsDisplayFragment } from './__generated__/productDetailsAwardDisplay'

export type ProductDetailsAwardDisplayProps = {
  hr?: boolean
  data: AwardsDisplayFragment[]
};

export const productDetailHrStyles = css`
  padding-top: ${props => rem(73, props.theme)};
  border-top: solid 3px #e0e0e0;

  ${props => props.theme.breakpoints.up('md')} {
    border-top-width: 3px;
    margin: ${props => rem(150, props.theme)} 0 ${props => rem(50, props.theme)};
    padding-top: ${props => rem(150, props.theme)};
  }
`

export const StyledAwardDescription = styled(Grid)<{ $hr?: boolean }>`
  margin: ${props => rem(0, props.theme)} 0 ${props => rem(50, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    margin: ${props => rem(150, props.theme)} 0 ${props => rem(50, props.theme)};
  }

  & > div > div > p {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
    }
  }

  ${props => props.$hr && productDetailHrStyles}
  .MuiGrid-item {
    p,
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const StyledAwardTitle = styled(Grid)`
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${irem(25)};
  font-size: ${props => rem(22, props.theme)};
  font-weight: 500;

  ${props => props.theme.breakpoints.up('md')} {
    margin: 0 auto ${props => rem(100, props.theme)};
    font-size: ${props => rem(32, props.theme)};
  }
`

export const StyledAwardImages = styled(Grid)`
    margin: 0;
`

const StyledAwardImage = styled(Grid)`
  margin-bottom: ${props => rem(35, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
  ${props => props.theme.breakpoints.up('xs')} {
    text-align: center;
  }
`

export const ProductDetailsAwardDisplay: React.FC<PropsWithChildren<ProductDetailsAwardDisplayProps>> = ({ data, hr }) => {
  if (!data[0] || data[0].__typename !== 'awardDisplayRow_BlockType') {
    return null
  }

  const { awardContent, awardsImages, awardsTitle } = data[0]

  return awardsTitle ? (
    <>
      <StyledAwardDescription $hr={hr}>
        <Grid container>
          <StyledAwardTitle item>{awardsTitle}</StyledAwardTitle>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={8}
        >
          {awardContent && awardContent.length === 2 ? (
            <>
              {awardContent?.[0]?.awardsContent &&
                <Grid item xs={12} md={6}>
                  {html(awardContent[0].awardsContent)}
                </Grid>
              }
              {awardContent?.[1]?.awardsContent &&
                <Grid item xs={12} md={6}>
                  {html(awardContent[1].awardsContent)}
                </Grid>
              }
            </>
          ) : (
            <>
              {awardContent?.[0]?.awardsContent &&
              <Grid>
                {html(awardContent[0].awardsContent)}
              </Grid>
              }
            </>
          )}
        </Grid>
      </StyledAwardDescription>
      {awardsImages &&
        <StyledAwardImages container justifyContent="center" spacing={FLEXIBLE_CONTENT_BASE_SPACING}>
          {nonNull(awardsImages).map((awardImage, index) => {return !awardImage || awardImage.__typename !== 'awardsImages_awardsImages_BlockType' ? null :
            <React.Fragment key={index}>
              {awardImage?.awardsImages?.[0]?.url &&
            <StyledAwardImage item key={index}>
              <Image
                src={awardImage.awardsImages[0].url}
                alt=""
                width={130}
                height={130}
              />
            </StyledAwardImage>
              }
            </React.Fragment>
          })}
        </StyledAwardImages>
      }
    </>
  ) : null
}

export const PRODUCT_DETAILS_AWARD_DISPLAY_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment AwardsDisplayFragment on awardDisplayRow_BlockType {
    awardsTitle
    awardContent {
      ... on awardContent_awardsContent_BlockType {
        awardsContent
      }
    }
    awardsImages {
      ... on awardsImages_awardsImages_BlockType {
        awardsImages {
          ...ImageFragment
        }
      }
    }
  }
`
